import styled from "styled-components"

export const BreadCrumbsStyled = styled.div`
  padding: 0 30px;
  .breadcrumb {
    margin: 0;
    background-color: transparent;
    padding: 8px 0 30px 0;
    .breadcrumb-item a,
    .active,
    .breadcrumb-item + .breadcrumb-item::before,
    .breadcrumb-item + .breadcrumb-item {
      color: #b0b0b0;
      padding: 0;
      padding-right: 2px;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`
export const EstateStatusStyled = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  span {
    text-transform: none;
  }
  .status {
    padding: 5px 10px;
    display: inline-block;
    border-radius: 4px;
  }
  .in-progress {
    color: #94c835;
    font-weight: 500;
    border: 1px solid #94c835;
  }
  .done {
    color: #94c835;
    font-weight: 500;
    border: 1px solid #94c835;
  }
  .completed {
    color: #b5b5b5;
    font-weight: 500;
    border: 1px solid #b5b5b5;
  }
  .trailer {
    color: #94c835;
    font-weight: 500;
    border: 1px solid #94c835;
  }
  .premiere {
    color: #e3c413;
    font-weight: 500;
    border: 1px solid #e3c413;
  }
  .dark {
    color: black;
    font-weight: 500;
    border: 1px solid black;
  }
  .accent {
    color: #c80000;
    font-weight: 500;
    border: 1px solid black;

    @media (max-width: 768px) {
      white-space: normal;
      clear: both;
      max-width: 100%;
      min-width: 150px;
    }
    @media (max-width: 500px){
      min-width: calc(100vw - 60px);
    }
  }
`

export const EstateTitleStyled = styled.div`
  h1 {
    font-size: 44px;
    font-weight: 500;
    @media only screen and (min-width: 991px) {
      margin: 7px 0 ${props => (props.hasContent ? "40px" : "10px")};
    }
    @media (min-width: 1200px) {
      white-space: ${props => (props.canWrap ? "wrap" : "nowrap")};
    }
    span {
      white-space: nowrap;
    }
  }
  p {
    strong {
      font-weight: 500;
    }
  }
  p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0 9px 0;
    @media only screen and (min-width: 991px) {
      margin-bottom: 30px;
    }
  }
  p:nth-of-type(2) {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    @media only screen and (max-width: 767px) {
      margin: 0 0 30px;
    }
  }
  @media only screen and (min-width: 767px) {
    margin-right: 15px;
  }
`

export const EstateNavStyled = styled.div`
  margin-bottom: 77px;
  .estate-nav-padding {
    padding: 0;
  }
  padding: 0;
  @media only screen and (min-width: 768px) {
    margin: 0;
    padding-left: 60px;
  }
  .nav-element-container {
    padding: 0;
    a {
      text-decoration: none;
      font-weight: 400;
    }
  }
  .nav-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #dadada;
    padding: 25px 0;
    position: relative;
    color: #000 !important;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 500;
    cursor: pointer;
    &:after {
      position: absolute;
      right: 16px;
      top: 50%;
      transition: 300ms;
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #000;
      transition: 300ms;
      border-right: 2px solid #000;
      opacity: 0.2;
      transform: rotate(45deg) translateY(-50%);
    }
    :hover {
      background: rgb(248, 248, 248);
      background: linear-gradient(
        90deg,
        rgba(248, 248, 248, 0) 0%,
        rgba(248, 248, 248, 0.28335084033613445) 8%,
        rgba(248, 248, 248, 0.5242471988795518) 17%,
        rgba(248, 248, 248, 0.8267682072829132) 29%,
        rgba(248, 248, 248, 1) 61%
      );
    }
    :hover::after {
      opacity: 1;
    }
    @media only screen and (min-width: 768px) {
      padding: 10px 0;
    }
  }
  .active-tab {
    font-weight: 500 !important;
    &:after {
      display: none;
    }
  }
  .img-container {
    min-width: 40px;
    width: 40px;
    img {
      height: 25px;
    }
  }
`

export const VisualContainer = styled.div`
  background-color: #f8f8f8;
  position: relative;
  padding: 100px 0 0;
  /* @media only screen and (min-width: 1440px) {
    padding: 60px 120px 0;
  } */
  .no-padding {
    margin: auto;
    /* padding: 0; */
  }
  h2 {
    margin-bottom: 40px;
    font-size: 48px;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  .wiz-title {
    margin: 0;
    padding: 0;
  }
  .wiz-container {
    min-height: 365px;
    margin: 0;
    padding: 0;
  }

  .wiz-text {
    padding: 24px 40px 50px 0;
    strong {
      font-weight: 500;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
    }
    p {
      font-weight: 200;
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    .wiz-container:nth-child(even) {
      .wiz-text {
        padding-right: 40px;
      }
      .wiz-img {
        img {
          margin-right: 40px;
        }
      }
    }
    .wiz-container:nth-child(odd) {
      .wiz-text {
        padding: 24px 5px 50px 40px;
      }
      .wiz-img {
        img {
          left: 0;
          margin-left: 40px;
        }
      }
    }
  }
  .wiz-img {
    height: 315px;
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &.wiz-img--tall {
      height: 380px;
    }

    img {
      position: absolute;
      right: 0;
      width: auto;
      height: 100%;
      @media only screen and (min-width: 600px) {
        width: 100%;
        height: initial;
      }
      @media only screen and (min-width: 768px) {
        width: auto;
        height: 100%;
      }
      @media only screen and (min-width: 1200px) {
        width: 100%;
        height: auto;
      }
      @media only screen and (min-width: 1440px) {
        width: 100%;
        height: auto;
        top: -60px;
      }

      &.cover {
        object-fit: cover;
        height: 100%;
        width: 100%;
        top: 0;
      }
    }
    //@media only screen and (max-width: 768px) {
    //  .vis--img--item1,
    //  .vis--img--item2,
    //  .vis--img--item5 {
    //    left: 0;
    //  }
    //  .vis--img--item3,
    //  .vis--img--item4,
    //  .vis--img--item6,
    //  .vis--img--item7,
    //  .vis--img--item8 {
    //    right: -50%;
    //    left: -50%;
    //    margin: 0 auto;
    //  }
    //}
  }
`
