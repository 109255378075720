import React from "react"
import { EstateTitleStyled } from "./estatesStyle"
const EstateTitle = ({ mainTitle, mainSubtitle, subTitleBold, subtitle, canWrap }) => {
  return (
    <EstateTitleStyled
      hasContent={!!subTitleBold || !!subtitle}
      canWrap={canWrap}
    >
      {mainTitle && <h1>{mainTitle} {mainSubtitle && <span>{mainSubtitle}</span>}</h1>}
      {subTitleBold && <p>{subTitleBold}</p>}
      {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </EstateTitleStyled>
  )
}

export default EstateTitle
