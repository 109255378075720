import React from "react"
import styled from "styled-components"
import ProgramInfoBarItem from "@/components/program/programInfoBarItem"

import img1 from "@/images/z-9/icons/icon-1.png"
import img2 from "@/images/z-9/icons/icon-2.png"
import img3 from "@/images/z-9/icons/icon-3.png"
import img4 from "@/images/z-9/icons/icon-4.png"
import ProgramInfoBarArrow from "@/components/program/programInfoBarArrow"

const ProgramInfoBar = () => {
  return (
    <ProgramInfoBarContainer>
      <h2>Jak to zrobić?</h2>
      <div className="wrapper">
        <a
          href="/dokumenty/WILLBUD-formularz-3000-17.03.2023.pdf"
          rel="noopener nofollow"
          target="_blank"
        >
          <ProgramInfoBarItem icon={img1}>
            <h3>
              Wypełnij
              <br />
              formularz
            </h3>
            <p>
              Wydukuj i wypełnij formularz zgłoszeniowy dostępny na stronie
              <br />
              <strong>www.willbud.pl</strong>
              <br />
              lub w naszych biurach sprzedaży
            </p>
          </ProgramInfoBarItem>
        </a>
        <ProgramInfoBarArrow />
        <ProgramInfoBarItem icon={img2}>
          <h3>Przekaż formularz do naszego biura</h3>
          <p>
            Dostarcz wypełniony formularz do naszego biura. Możesz to zrobić
            osobiście lub przesłać formularz pocztą.
          </p>
        </ProgramInfoBarItem>
        <ProgramInfoBarArrow />
        <ProgramInfoBarItem icon={img3}>
          <h3>
            Podpisanie
            <br />
            umowy
          </h3>
          <p>
            Osoba, której poleciłeś nasze mieszkanie podpisuje umowę
            deweloperską i wpłaca I transzę ceny.
          </p>
        </ProgramInfoBarItem>
        <ProgramInfoBarArrow />
        <ProgramInfoBarItem icon={img4}>
          <h3>
            Przekazanie
            <br />
            nagrody
          </h3>
          <p>
            Willbud przekazuje na Twoje konto nagrodę w wysokości{" "}
            <strong>3000zł</strong>.
          </p>
        </ProgramInfoBarItem>
      </div>
    </ProgramInfoBarContainer>
  )
}

export default ProgramInfoBar

export const ProgramInfoBarContainer = styled.div`
  padding: 30px 0;

  > h2 {
    margin-bottom: 40px;
  }

  .wrapper {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: ${props =>
      props.five
        ? "1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr"
        : "1fr 40px 1fr 40px 1fr 40px 1fr"};
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }
`
