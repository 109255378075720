import React from "react"
import styled from "styled-components"

const ProgramInfoBarItem = ({ children, icon = null }) => {
  return (
    <ProgramInfoBarItemContainer hasImage={!!icon}>
      {icon && (
        <div className="image">
          {" "}
          <img src={icon} alt="..." />
        </div>
      )}
      <div className="body">{children}</div>
    </ProgramInfoBarItemContainer>
  )
}

export default ProgramInfoBarItem

export const ProgramInfoBarItemContainer = styled.div`
  padding: 40px 0;
  border: 1px solid #999;
  color: black;
  transition: 0.2s border-color;
  display: ${props => (props.hasImage ? "div" : "flex")};
  align-items: center;

  a:hover > & {
    border-color: #94c835;
    h3 {
      color: #94c835;
    }
  }

  .image {
    text-align: center;
    display: flex;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    img {
      vertical-align: top;
    }
  }

  .body {
    padding: 0 30px;
    text-align: center;

    strong {
      font-weight: 500;
    }

    h3 {
      font-size: 19px;
      font-weight: 500;
      text-transform: uppercase;
      min-height: 2.5em;
      transition: 0.2s color;
      + p {
        margin-top: 20px;
      }
    }
  }
`
