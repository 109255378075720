import React from "react"
import styled from "styled-components"
import arrowImg from '@/images/z-9/icons/arrow-icon.png'

const ProgramInfoBarArrow = () => {
  return <ProgramInfoBarArrowContainer />
}

export default ProgramInfoBarArrow

export const ProgramInfoBarArrowContainer = styled.div `
  background: url(${arrowImg}) center center no-repeat;
  min-width: 40px;
  min-height: 40px;

  @media(max-width: 1200px) {
    transform: rotate(90deg)
  }
`
